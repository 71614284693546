@import "./../../constants.scss";

.landingPage {
  @include page-layout;
}

.landingPage--center-flex {
  @include center-flex;

  width: 100vw;
  height: 100vh;
}

.landingPage--ATriangle {
  height: 0.75em;
  margin-bottom: -0.1rem;
}

.landingPage--title {
  margin-top: 4rem;
  color: white;
  line-height: 3rem;
  text-align: center;
  font-size: 3rem;
}

.landingPage--description {
  color: $white-gray;
  font-size: 1rem;
}

.landingPage--scroll {
  z-index: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2rem;
  margin-bottom: 2rem;
  animation: point-down 2s infinite;
  align-self: center;
  margin-top: 4rem;
}

.landingPage--scroll-text {
  color: white;
  text-align: center;
  font-size: 1rem;
  line-height: 0.5;

  &:hover {
    color: $cyan;
  }
}

.landingPage--scroll-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

// Title

.landingPageBouncy {
  display: inline-block;
  transition: 0.5s;
}

.landingPage--title .text-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  align-self: center;
}

.landingPage--title .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1.5rem;
}
