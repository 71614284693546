@import "./constants.scss";

.appBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 912px) {
  html {
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $gray;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $cyan;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $red;
    }
  }
}

@media only screen and (min-width: 913px) {
  html {
    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $gray;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $cyan;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $red;
    }
  }
}

.app-body-visible {
  visibility: inherit;
}

.app-body-invisible {
  visibility: inherit;
}
