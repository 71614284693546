@import "./../../constants.scss";

.contactMe {
  @include page-layout;
}

.contactMe--center-flex {
  @include center-flex;

  width: 100vw;
}

.contactMe--title {
  color: white;
  line-height: 0;
  margin-top: 0.5rem;
  font-size: 2rem;
  color: $cyan;
}

.contactMe--description {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80vw;
  // padding-left: 2rem;
  // padding-right: 2rem;
  padding: 0rem 2rem 1rem 2rem;

  color: white;
  text-align: center;
  font-size: 1rem;
}

.contactMe--form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;

  padding-left: 2rem;
  padding-right: 2rem;
}

.input-form-started {
  border-bottom: 1px solid $red !important;
  background-color: rgba($color: $red, $alpha: 0.25) !important;
}

.input-form-finished {
  border-bottom: 1px solid $cyan !important;
  background-color: rgba($color: $cyan, $alpha: 0.1) !important;
}

.contactMe--input-error {
  margin: 0;
  color: rgba($color: $red, $alpha: 075) !important;
}

.contactMe--input {
  background-color: $gray;
  color: white;
  border: 0;
  width: 100%;

  font-family: "Roboto";
  padding: 0.5rem 0 0.5rem 0.5rem;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  font-size: 1rem;

  &::placeholder {
    padding-left: 0.5rem;
    font-size: 1rem;
    color: $white-gray;
    font-family: "Roboto";
  }
  &:-ms-input-placeholder {
    font-size: 1rem;
    color: $white-gray;
  }
  &::-ms-input-placeholder {
    font-size: 1rem;
    color: $white-gray;
  }

  &:focus::placeholder {
    color: transparent !important;
  }
}

.contactMe--textarea {
  height: 6rem;
}

.contactMe--submit-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  width: 100%;
}

.contactMe--submit-btn {
  &:disabled {
    color: $gray;
    cursor: not-allowed;
  }
}

.contactMe--lkdn-logo {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.toast-general {
  font-family: "Bitter, sans" !important;
  font-size: 1.1rem;
  color: white !important;

  background-color: $bg-black !important;
  border-radius: 1rem solid !important;
  margin: 1px solid $cyan !important;
}

// Title

.contactMeBouncy {
  display: inline-block;
  transition: 0.5s;
}

.contactMe--title .text-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.contactMe--title .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1em;
}
