html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  padding: 0;

  /* font-family: "Bitter, sans"; */
  font-family: "Roboto";
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.05rem;

  background-color: #1e1e1e;
}

@media only screen and (max-width: 912px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 913px) {
  html {
    font-size: 17px;
  }
}

/* * {
  border: 1px solid red;
} */
