@import "./../../constants.scss";

.aboutMe {
  @include page-layout;
}

.aboutMe--center-flex {
  @include center-flex;

  min-height: 60vh !important;
  width: 100vw;
}

.aboutMe--title {
  line-height: 0;
  font-size: 2rem;
  color: $cyan;
}

.aboutMe--ATriangle-mobile {
  margin-bottom: 0.15em;
  margin-bottom: 1.3rem;
}

.aboutMe--ATriangle {
  height: 0.75em;
}

.aboutMe--description {
  font-size: 1rem;

  padding: 0 2rem 2rem 2rem;
  font-weight: 500;

  color: white;
}

.aboutMe--description-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100vw;

  font-weight: 100;
  text-align: center;
  color: white;
}

.aboutMe--description-item {
  margin: 0 1rem;
}

.aboutMe--description-item-years {
  color: $cyan;
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.aboutMe--description-item-title {
  margin: 0;
  font-weight: 100;
  font-size: 1rem;
}

// DESKTOP
.first-column {
  @include first-column;

  flex-direction: column;
  justify-content: space-around;
}

.second-column {
  @include second-column;
}

.aboutMe--description-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// Title

.aboutMeBouncy {
  display: inline-block;
  transition: 0.5s;
}

.aboutMe--title .text-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.aboutMe--title .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1em;
}
