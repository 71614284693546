@import "./../../constants.scss";

.header {
  position: relative;
  z-index: 4;
}

.header--body {
  position: fixed;
  width: 100vw;

  transition: 0.5s;
  z-index: 5;
  height: 4em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  background-color: $bg-black-transparent;
}

.header--logo {
  height: 120%;
  margin-top: -0.4em;
}

.header--burger-menu-btn {
  background: transparent;
  cursor: pointer;
  border: 0;
  margin-right: 1em;
}

.header--modal-menu {
  @include center-flex;

  width: 100vw;
  opacity: 1 !important;
}

.header--modal-body {
  @include center-flex;
  position: fixed;

  width: 100vw;
  height: 100vh;

  transition: 0.5s;
  animation: fadein 0.5s;
  background-color: $bg-black;

  overflow-x: hidden;
  overflow-y: scroll;
}

.header--modal-title {
  color: white;
  line-height: 0;
  font-size: 2em;

  margin-bottom: 2em;
}

.header--triangle {
  height: 0.75em;
  margin-bottom: -0.1rem;
}

.header--modal-bar {
  width: 80vw;
  border-bottom: 0.15em solid $gray;
}

.header--modal-item {
  cursor: pointer;
  color: white;
  font-size: 1.1em;
}

.header--modal-ldkn-logo {
  margin-top: 4em;
  cursor: pointer;
}

// DESKTOP

.header--body--desktop {
  background-color: $bg-black-transparent;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3.5em;
  width: 100vw;
}

.header--name-logo {
  margin-left: 1em;
  color: white !important;
  font-size: 2.25em;
  &:hover {
    transition: 0.5s;
    color: $cyan !important;
  }
}

.header--triangle-element {
  height: 0.8em;
}

.header--links-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header--group-item {
  margin-right: 1em;
}

.header--group-item-logo {
  height: 2.5em;
}

.header--group-item-text {
  color: white !important;
  font-size: 1.1em;
  font-weight: 600;
}
