// Colours
$cyan: #00f6ef;
$cyan-transparent: rgba($cyan, 0.4);
$cyan: #00f6ef;
$red: #fe004e;
$bg-black: #1e1e1e;
$bg-black-transparent: rgba(black, 0.8);
$gray: #3c3c3c;
$white-gray: rgba(255, 255, 255, 0.5);

.cyan-btn-selected {
  transition: 0.5s;
  background-color: $cyan-transparent !important;
  color: white !important;
}

.main-btn-style {
  background-color: transparent;
  border: 0.1em solid $cyan;
  transition: 0.5s;

  font-size: 1.05em;
  font-weight: 400;
  color: $cyan;
  letter-spacing: 0.2em;

  height: 2.8rem;
  width: 12rem;
  cursor: pointer;

  &:hover {
    transition: 0.5s;
    background-color: $cyan-transparent;
    color: white;
  }
}

.invisible--buton {
  margin: 0;
  border: 0;
  background-color: transparent !important;
  cursor: pointer;
  &:hover {
    transition: 0.5s;
    color: $cyan !important;
  }
}

.cyan-text {
  transition: 0.5s;
  color: $cyan !important;
}

.gray-text {
  color: $white-gray;
}

.two-columns {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;

  margin-left: 2rem;
  margin-right: 2rem;

  width: 100vw;

  p {
    margin: 2rem;
  }
}

.bouncing {
  animation: rubberband 600ms alternate ease-out;
  color: rgba($color: $cyan, $alpha: 1);
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: initial !important;
}

.align-text-center {
  text-align: center;
}

.letters {
  line-height: 4rem;
}

/////////////////////////////
// HELPERS
/////////////////////////////

@mixin page-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  overflow-y: hidden;
}

@mixin center-flex {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin first-column {
  display: flex;
  max-width: 50vw;
  width: 100vw;
  margin: 1rem;

  font-size: 1rem;
  text-align: center;
  color: white;
}

@mixin second-column {
  width: 100vw;
  font-size: 1rem;
  color: white;
  text-align: left;
  margin: 1rem;
}

/////////////////////////////
// ANIMATIONS
/////////////////////////////

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes title-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes come-from-below {
  from {
    transform: translateY(5rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes point-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rubberband {
  0% {
    transform: scaleX(1);
  }
  40% {
    transform: scaleX(1.12) scaleY(0.75);
  }
  55% {
    transform: scaleX(0.85) scaleY(1);
  }
  65% {
    transform: scaleX(1.09) scaleY(0.85);
  }
  75% {
    transform: scaleX(0.9) scaleY(1);
  }
  90% {
    transform: scaleX(1.05) scaleY(0.95);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

///////////////////////////////////
// ANIMATIONS -- LANDINGPAGE --
///////////////////////////////////

.landingPage--name-popin {
  opacity: 0;
  animation-name: title-fadein;
  animation-duration: 1.5s;
  animation-delay: 0.6s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

.landingPage--item-popin {
  opacity: 0;
  animation-name: title-fadein;
  animation-duration: 1.5s;
  animation-delay: 1.2s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

.come-from-below-fast {
  opacity: 0;
  animation-name: come-from-below;
  animation-duration: 1s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

.come-from-below {
  opacity: 0;
  animation-name: come-from-below;
  animation-duration: 1s;
  animation-delay: 0.5s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

.come-from-below-delayed {
  opacity: 0;
  animation-name: come-from-below;
  animation-duration: 1s;
  animation-delay: 1s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

.come-from-below-delayed-more {
  opacity: 0;
  animation-name: come-from-below;
  animation-duration: 1s;
  animation-delay: 1.5s;

  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}
