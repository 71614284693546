@import "./../../constants.scss";

.experience {
  @include page-layout;
}

.experience--center-flex {
  @include center-flex;

  width: 100vw;
}

.experience--title {
  color: white;
  line-height: 0;
  font-size: 2rem;
  color: $cyan;
  margin-bottom: 1rem;
}

.experience--btn-group {
  margin: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  width: 90%;
}

.experience--btn-tab {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.5rem;
}

.experience--btn {
  cursor: pointer;
  width: 40vw;
  height: 100%;
  border: 0;
  color: white;
  background-color: transparent;
}

.experience--item-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  color: white;
}

.experience--item-general-box {
  background-color: rgba($color: $cyan, $alpha: 0.1) !important;
  margin-top: 1em;
  padding-top: 0.5em;
  border-radius: 1rem 1rem 0 0;
  border-top: 0.1em solid $cyan;
  border-left: 0.1em solid $cyan;
  border-right: 0.1em solid $cyan;
  border-bottom: 0.01em solid rgba($color: $cyan, $alpha: 0.2);

  width: 90vw;
  color: white;
}

.experience--item-general-box-title {
  font-size: 1.1rem;
  margin: 0 0 0 0.5rem;
  text-align: center;
}

.experience--item-general-box-date {
  font-size: 1rem;
  margin: 0 0 0 0.5rem;
  color: $cyan;
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
}

.experience--item-general-box-description {
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  font-size: 1rem;
  font-weight: 100;
}

.experience--item-box-see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: transparent;
  border: 0;
  width: 100%;

  cursor: pointer;
  margin-top: 1rem;
  text-align: center;
}

.experience--item-box-see-more-bar {
  width: 100%;
  padding: 0.01em;
  background-color: $cyan;
}

.experience--item-box-see-more-text {
  font-weight: 100;
  color: $cyan;
  font-size: 1rem;
  &:hover {
    color: $cyan-transparent !important;
  }
}

// DESKTOP

.two-rows {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;

  margin-left: 2rem;
  margin-right: 2rem;

  width: 100vw;
}

.first-row {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 4rem;
  margin-right: 2rem;

  width: 100vw;
}

.second-row {
  margin-left: 2rem;
  margin-right: 2rem;

  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.experience--info-box-group {
  display: flex;
  align-self: flex-start;
}

.experience--item-grid-desktop {
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100vw;
}

.experience--item-description-desktop {
  display: flex;
  justify-content: center;
  align-content: space-between;

  height: fit-content;
  width: calc(50vw - 1.5rem);
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  font-size: 1rem;
  font-weight: 100;
  border-radius: 1rem;

  color: white;
}

// Title

.experienceBouncy {
  display: inline-block;
  transition: 0.5s;
}

.experience--title .text-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.experience--title .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1em;
}
